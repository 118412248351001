import { FilterI } from "components/Filter/types";

export const createFilterStructure = (params: any, options: any): FilterI[] => {
  console.log(params);
  return params ? [
    {
      type: "autocomplete-order",
      name: "order",
      label: "Ordine",
      active: params?.order !== undefined,
      value: params?.order,
    },
    {
      type: "autocomplete-client",
      name: "order__client",
      label: "Cliente",
      active: params?.order__client !== undefined,
      value: params?.order__client,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
    {
      type: "boolean",
      name: "has_driver",
      label: "Assegnata all'autista",
      active: params?.no_driver !== undefined,
      value: params?.no_driver,
    },
    {
      type: "date",
      name: "date_after",
      label: "Data carico/scarico dal",
      active: params?.order__date_after !== undefined,
      value: params?.order__date_after,
    },

    {
      type: "date",
      name: "date_before",
      label: "Data carico/scarico al",
      active: params?.order__date_before !== undefined,
      value: params?.order__date_before,
    },

    {
      type: "options",
      name: "order_point_type",
      label: "Tipo",
      options: options.order_point_type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.order_point_type !== undefined,
      value: params?.order_point_type,
    },
    {
      type: "text",
      name: "unload_plate",
      label: "Rimorichio",
      active: params?.unload_plate !== undefined,
      value: params?.unload_plate,
    },
    {
      type: "text",
      name: "document_number",
      label: "Numero documento",
      active: params?.document_number !== undefined,
      value: params?.document_number,
    },


  ] : [];
};
