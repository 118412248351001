import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { OrderForm } from "./OrderForm";
import { Order, OrderPoint } from "Traction/types";
import { UpdatePayload } from "shared/types";
import { useOptionsDestinyTraction } from "Traction/hooks/destinyTraction";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { useState } from "react";
import { useOptionsOrder } from "Traction/hooks/orders";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Order>,
    unknown
  >;
  order: Order;
}

export const UpdateOrderForm = ({ submitFunction, order }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Order, "client" | "orderpoints"> & {
      orderpoints: NestedValue<OrderPoint[]>;
    }
  >({
    defaultValues: { ...order },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "orderpoints",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsOrder();

  const onSend = handleSubmit((data) => {
    if (
      data.orderpoints.every((v, i, a) => {

        if (i === 0) return true;

        let d_1 = new Date(a[i].date!);
        d_1.setHours(d_1.getHours() + 3);
        d_1 = new Date(d_1.toISOString().substring(0, 10))

        let d_2 = new Date(a[i - 1].date!);
        d_2.setHours(d_2.getHours() + 3);
        d_2 = new Date(d_2.toISOString().substring(0, 10))

        return d_1 >= d_2;
      })
    ) {
      submitFunction({ id: order.id!, newValues: { ...data } })
        .then(() => {
          toast.success("Ordine modificato!");
        })
        .catch((errors) => {
          const data = errors.response.data;
          addServerErrors(data, setError);
        });
    } else {
      toast.error("Attenzione rispettare ordine temporale");
    }

  });
  const [disabled, setDisabled] = useState(true);

  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_delivery"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <OrderForm
          watch={watch}
          options={options}
          append={append}
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          fields={fields}
          remove={remove}
          defaultValues={order}
          editable={!disabled}
        />
      )}

      <CheckPermissions permissions={["rosina.change_order"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success">
            Salva
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
