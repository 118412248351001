import React, { Ref, useEffect, useMemo, useState } from "react";
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight } from "react-feather";
import { useFilters, useRowSelect, useSortBy, useTable } from "react-table";

import { Col, Pagination, PaginationItem, Row } from "reactstrap";
import { DefaultColumnFilter } from "./DefaultColumnFilter";

interface Props {
  columns: any;
  data: any;
  setPage: (page: number) => void;
  pageIndex: number;
  sortBy: string;
  setSelectedRows?: any;
  pageCount: number;
  endTableRef?: any;
  disableColumnSort?: boolean;
}


export const Table = ({
  columns,
  data,
  sortBy,
  setSelectedRows,
  setPage,
  pageCount,
  pageIndex,
  endTableRef,
  disableColumnSort,
}: Props) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [segna, setSegna] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      defaultColumn, // Be sure to pass the defaultColumn option
    },
    useFilters, // useFilters!
    useSortBy,
    useRowSelect
  );

  useEffect(() => {

    if (setSelectedRows && segna !== selectedFlatRows.map((row) => row.original).length) {
      setSegna(selectedFlatRows.map((row) => row.original).length)
      setSelectedRows(selectedFlatRows.map((row) => row.original));
    }

  }, [setSelectedRows, selectedFlatRows]);


  const goNextPage = () => {
    setPage(pageIndex + 1);
  };
  const goPrevPage = () => {
    setPage(pageIndex - 1);
  };

  const canNextPage = () => {
    return pageIndex + 1 <= pageCount;
  };

  const canPrevPage = () => {
    return pageIndex - 1 > 0;
  };
  return (
    <>
      <div
        style={{ maxHeight: "700px", paddingLeft: "5px", paddingRight: "5px" }}
        className="table-responsive"
      >
        <table {...getTableProps()} className="table table-flush">
          <thead>
            {headerGroups.map((headerGroup) => [
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(disableColumnSort === true ? {} : column.getSortByToggleProps())}
                  >
                    <div className="d-flex align-items-center">
                      {column.render("Header")}
                      <span className="ml-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown />
                          ) : (
                            <ArrowUp />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>,
              headerGroup.headers.find(e => e.disableFilters !== true) ?
                <tr key={"secondary-row"}>
                  {headerGroup.headers.map((column, index) => (
                    <th key={"filter-" + index.toString()}>
                      <Row>
                        <Col xs="12">
                          {column.canFilter ? column.render("Filter") : null}
                        </Col>
                      </Row>
                    </th>
                  ))}
                </tr> : null
              ,

            ])}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={i % 2 ? "primary-row odd" : "primary-row "}
                  key={"principle" + i.toString()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        data-label={
                          typeof cell.column.Header === "string"
                            ? cell.column.Header
                            : "Seleziona"
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr ref={endTableRef}></tr>
          </tbody>
        </table>
      </div>

      <nav>
        <Pagination
          className="pagination pagination-sm justify-content-end m-3"
          listClassName="pagination-sm justify-content-end mb-0"
        >
          <PaginationItem disabled={!canPrevPage()}>
            <button className="page-link" onClick={() => goPrevPage()}>
              <ChevronLeft size={13} />
            </button>
          </PaginationItem>
          <span className="ml-2 mr-2   my-auto" style={{ fontSize: "small" }}>
            Pag: {pageIndex} di {pageCount}
          </span>

          <PaginationItem disabled={!canNextPage()}>
            <button className="page-link" onClick={() => goNextPage()}>
              <ChevronRight size={13} />
            </button>
          </PaginationItem>
        </Pagination>
      </nav>
    </>
  );
};
