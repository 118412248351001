import React from "react";
import { Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import it from "date-fns/locale/it";
import classNames from "classnames";

registerLocale("it", it);

interface Props {
  control: any;
  name: string;
  disabled?: boolean;
  label?: string;
  errorMessage?: string;
  defaultValue?: string | Date;
  className?: string;
  required?: boolean;
}

export const DateTimeField = ({
  control,
  name,
  disabled,
  label,
  errorMessage,
  defaultValue,
  className,
  required,
}: Props) => {
  return (
    <FormGroup className={classNames(className)}>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ onChange, value }) => {
          return (
            <DatePicker
              required={required}
              locale={it}
              disabled={disabled}
              dateFormat="dd/MM/yyyy HH:mm:ss"
              showTimeInput={true}
              selected={value !== null && value ? new Date(value) : null}
              onChange={onChange}
              className="form-control"
            />
          );
        }}
      />
      {errorMessage && (
        <FormFeedback className="d-block">{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};



export const DateField = ({
  control,
  name,
  disabled,
  label,
  errorMessage,
  defaultValue,
  className,
  required,
}: Props) => {
  return (
    <FormGroup className={classNames(className)}>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ onChange, value }) => {
          return (
            <DatePicker
              required={required}
              locale={it}
              disabled={disabled}
              dateFormat="dd/MM/yyyy"
              showTimeInput={false}
              selected={value !== null && value ? new Date(value) : null}
              onChange={onChange}
              className="form-control"
            />
          );
        }}
      />
      {errorMessage && (
        <FormFeedback className="d-block">{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};




export const TimeField = ({
  control,
  name,
  disabled,
  label,
  errorMessage,
  defaultValue,
  className,
  required,
}: Props) => {
  return (
    <FormGroup className={classNames(className)}>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ onChange, value }) => {
          return (
            <Input
              disabled={disabled}
              className="form-control"
              onChange={onChange}
              type={"time"}
              required={required}
              defaultValue={value}
            />
          );
        }}
      />
      {errorMessage && (
        <FormFeedback className="d-block">{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};


